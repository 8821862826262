import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getUser, checkToken } from '../../store/Usuarios';
import { Form, Button, FloatingLabel } from 'react-bootstrap';
import Logo from '../../images/LogoTovCare.png'

  
const Login = () => {
    const {erroMassage, isLoading} = useSelector(state => state.userReduce)
    const dispatch = useDispatch()

    const handleSubmit = async (event) => {
        const form = {
            usuario: event.target.floatingUser.value || '',
            senha:event.target.floatingPassword.value || ''
        }
        event.preventDefault();
        dispatch(getUser(form))
    };
    
    return(
        <div className="md:grid  md:grid-cols-7 md:gap-1 md:grid-rows-3 xl:grid-cols-3 ">
            <div className="md:col-span-3 md:col-start-3 xl:col-span-1 xl:col-start-2 mt-5 mx-2">
                <div className="flex flex-col border-2 rounded-2xl border-t-blue-400">
                    <div className=" flex border-b-2 border-gray-200 py-4 justify-center">
                        <img className="will-change-auto" src={Logo}/>
                    </div>
                    <div className="flex flex-col text-center p-3">
                        <p>Faça o login para continuar<br/></p>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <FloatingLabel controlId="floatingUser" label="Usuário" className="my-2 w-full">
                                    <Form.Control required type="text" placeholder="E-mail" />
                                </FloatingLabel>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <FloatingLabel controlId="floatingPassword" label="Senha" className="my-2 w-full">
                                    <Form.Control required type="password" placeholder="Senha" autoComplete="on" />
                                </FloatingLabel>
                            </Form.Group>
                            <p className=" text-red-500">
                                {erroMassage}
                            </p>
                            <Button disabled={isLoading ? true : false} variant="primary" type="submit" className="text-white bg-blue-400 hover:bg-blue-500 border-2 border-blue-400 hover:border-blue-500 rounded p-1 w-full" >
                                Entrar
                            </Button>
                        </Form>
                    </div>
                    <div className="py-3 px-5 border-t-2 border-gray-200">
                        <h6 className="text-xs text-center m-2 text-gray-300">Versão 0.05-beta</h6>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default Login

