import { useSelector } from 'react-redux';
import Sidebar from './components/sidebars/Sidebars'
import IncluirOrcamento from './screen/orcamento/IncluirOrcamento'
import EditarOrcamento from './screen/orcamento/EditarOrcamento'
import Orcamento from './screen/orcamento/Orcamento'

import Login from './screen/login/Login'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import { useEffect } from 'react';

import { getUser } from './store/Usuarios';



import {
  BrowserRouter,
  HashRouter,
  Routes,
  Route,
} from "react-router-dom";


function App() {
  // const dispatch = useDispatch()
  const {dataUser, token} = useSelector(state => state.userReduce)

  useEffect(() => {
    // dispatch(getTransp())
    // dispatch(getFilial())
    // dispatch(getCondpag()) 
    // dispatch(getPedido())    
    // dispatch(getCliente())    
    // dispatch(getDispProd())    
  },[dataUser,token.access_token]);
// },[dataUser]);

  if (dataUser[0] && getUser(dataUser[0] && token.access_token)){
      return (
          <Container className='container-lg m-auto p-0'>
            <HashRouter>
            <Sidebar/>
              <Routes>
                <Route path="/" element={<Orcamento/>}/>
                <Route path="/orcamentos/incluir" element={<IncluirOrcamento/>}/>
                <Route path="/orcamentos/incluir/:id" element={<IncluirOrcamento/>}/>
                <Route path="/orcamentos/editar" element={<EditarOrcamento/>}/>
                <Route path="/orcamentos" element={<Orcamento/>}/>
              </Routes>
            </HashRouter>
          </Container>
      )
  }
  else{
    return (
      <Container className='container-lg m-auto p-0'>
        <HashRouter>
          <Routes>
            <Route path="/" element={<Login/>}/>
          </Routes>
        </HashRouter>
      </Container>
    )
  }
  
}

export default App;
