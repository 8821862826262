export function convertDecimal(number) {
    // Convert number to string
    var numberStr = number.toString();
    
    // Replace "." with ","
    var convertedNumber = numberStr.replace(".", ",");
    
    return convertedNumber;
}

