import { createSlice } from '@reduxjs/toolkit'
import api from "../services/api";
import { jwtDecode } from 'jwt-decode';


const initialUser = localStorage.getItem('dataUser')
  ? JSON.parse(localStorage.getItem('dataUser'))
  : []
const initialToken = localStorage.getItem('Token')
  ? JSON.parse(localStorage.getItem('Token'))
  : []

const userReduce = createSlice({
    name: 'usuario',
    initialState: {
      dataUser: initialUser,
      token: initialToken,
      erroMassage: "",
      isLoading: false
    },
    reducers: {
      fetchUser: (state, action) => {
        state.dataUser = action.payload.user;
        state.token = action.payload.token;
        localStorage.setItem('dataUser', JSON.stringify(action.payload.user))
        localStorage.setItem('Token', JSON.stringify(action.payload.token))
        },

      addUser: (state, action) => {
        state.dataUser = [...state.dataUser, action.payload];
        // localStorage.setItem('dataUser', JSON.stringify(action.payload))
        },

      editUser: (state, action) => {
          state.dataUser = [...state.dataUser, action.payload];
          let index = state.dataUser.indexOf(action.payload)
          state.dataUser.splice(index, 1)
          // localStorage.setItem('dataUser', JSON.stringify(action.payload))
        },
      
      delUser: (state, action) => {
        state.dataUser = []
        state.token = []
        localStorage.setItem('dataUser', '')
        localStorage.setItem('Token', '')

      },

      setErroMassage: (state, action) => {
        state.erroMassage = action.payload
      },

      setloading: (state, action) => {
        state.isLoading = action.payload
      }
    },
  })

  export default userReduce.reducer


  ///////////////////////////  Actions ///////////////////////////////

const { fetchUser,  delUser, setErroMassage, setloading } = userReduce.actions

export const getUser = (form) => async dispatch => {
    dispatch(setloading(true))
    try {
      // const params = new URLSearchParams([['usuario', form.usuario]]);
     
      await api.get('/auth/token', { params: form })
      .then(async (response)  => {
        if(response.data && response.status === 200){
          // dispatch(fetchUser(response.data))
          dispatch(fetchUser({token: response.data, user: []}))
          try {
              let tokenDecoded = jwtDecode(response.data.access_token);
              if (tokenDecoded) {
                let userResp = await api.get('/vendedores', {headers: {Authorization:`Bearer ${response.data.access_token}`}, params: {idUsuario: tokenDecoded.userid}})
                .catch((response)=>console.log("teste",response))
                dispatch(fetchUser({token: response.data, user: userResp.data}))
                dispatch(setloading(false))
                dispatch(setErroMassage(""))
                return null;
              }
          } catch (err) {
              console.log("error on JwtDecode: ", err);
              return null;
          }
        }
      })
    }
    catch (e) {
      dispatch(setErroMassage("Login inválido"))
      dispatch(setloading(false))
      return console.error(e.message);
    }
}

export const logoutUser = () => dispatch => {
  try {
    dispatch(delUser())
  }
  catch (e) {
      return console.error(e.message);
  }
}


export const checkToken = () => {
  const initialToken = localStorage.getItem('Token')
  ? JSON.parse(localStorage.getItem('Token'))
  : []
  return initialToken.access_token
}

