import { createSlice } from '@reduxjs/toolkit'
import api from "../services/api";
import { checkToken } from "./Usuarios"

const initialTabPreco = []

const precoReduce = createSlice({
    name: 'preco',
    initialState: {
      dataTabPreco: initialTabPreco,
      selectTabelaPreco:{},
      loading: true
    },
    reducers: {
      fetchTabPreco: (state, action) => {
        state.dataTabPreco = action.payload;
        state.loading = false;
        // localStorage.setItem('dataTabPreco', JSON.stringify(action.payload))
      },
      setTabPreco: (state, action) => {
        state.selectTabelaPreco = action.payload;
        // localStorage.setItem("selectCli", JSON.stringify(action.payload));
      },
      
      delTabPreco: (state) => {
        state.selectTabelaPreco = {}
      }
    },
  })

  export default precoReduce.reducer


  ///////////////////////////  Actions ///////////////////////////////

const { fetchTabPreco, setTabPreco, delTabPreco} = precoReduce.actions
 
export const getTabPreco = (id) => async dispatch => {
    try {
      if(id) {
        const params = new URLSearchParams([['limit', 10], ['codTab', id]]);
        await api.get('/precos/TabPreco', { headers:{Authorization:`Bearer ${checkToken()}`}, params })
        .then((response) => dispatch(setTabPreco(response.data[0])))
      }else {
        const params = new URLSearchParams([['limit', 10]]);
        await api.get('/precos/TabPreco', { headers:{Authorization:`Bearer ${checkToken()}`}, params })
        .then((response) => dispatch(fetchTabPreco(response.data)))
      }

    }
    catch (e) {
        return console.error(e.message);
    }
}

export const selectTabPreco = (form) => async dispatch => {
  try {
    await dispatch(setTabPreco(JSON.parse(form)))
  }
  catch (e) {
      return console.error(e.message);
  }
}

export const deleteTabPreco = () => async dispatch => {
  try {
    await dispatch(delTabPreco())
  }
  catch (e) {
      return console.error(e.message);
  }
}
