import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { getPedido, putPedido } from '../../store/Pedidos';
import { Accordion, Dropdown, Table, FloatingLabel, Form, Modal, Button } from 'react-bootstrap';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { postRevisaoCarrinho } from '../../store/Carrinho';
import CondPagamento, { deleteCondpag, selectCondPag } from '../../store/CondPagamento';
import { deleteCliente } from '../../store/Clientes';
import { deleteTransp } from '../../store/Transportadora';
import { deleteTabPreco } from '../../store/Preco';
import { MdOutlineShoppingCart, MdAdd } from "react-icons/md";
import LoadingIcon from '../../components/loading/Loading';
// import ItemOrcamento from './componentes/ItemOrcamento';
import {convertDecimal} from '../../function/maskInputMoney';
import {converterData} from '../../function/convertData';
import { checkToken } from '../../store/Usuarios'
import api from "../../services/api";
import logoPromexMais from "../../images/LogoPromexMais.png";
import logo from "../../images/LogoTovCare.png";

// import Button from 'react-bootstrap/Button';
import { IoAdd, IoRemove, IoEllipse, IoClose, IoCheckmarkSharp, IoPencil, IoMailOpenOutline, IoDownload } from "react-icons/io5";



const Orcamento = () => {
    const { select } = useSelector(state => state.filialReduce)
    const { dataPedido, page, hasNext } = useSelector(state => state.pedidoReduce)
    const { dataUser } = useSelector(state => state.userReduce)
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [typeSearch, settypeSearch] = useState({ tittle: "Nome Cliente", type: 3, term: "" })
    const [typeStatus, setTypeStatus] = useState({ title: "Aguardando", status: 0 })


    useEffect(() => {
        dispatch(getPedido(select.idFilial, dataUser[0].IdErpUser, typeStatus, typeSearch, page))
        dispatch( deleteCondpag())
        dispatch( deleteCliente())
        dispatch( deleteTransp())
        dispatch( deleteTabPreco())
    }, [select, page, typeSearch.type, typeStatus]);


    const searchTerm = (term, page) => {
        settypeSearch({ ...typeSearch, term })
        dispatch(getPedido(select.idFilial, dataUser[0].IdErpUser, typeStatus, { ...typeSearch, term }, page))
    }

    const setPage = (page) => {
        dispatch(getPedido(select.idFilial, dataUser[0].IdErpUser, typeStatus, typeSearch, page))
    }

    const onClick = (value) => {
        switch (value) {
            case 1:
                settypeSearch({ ...typeSearch, tittle: "Número cotação", type: 1 });
                break;
            case 2:
                settypeSearch({ ...typeSearch, tittle: "Código cliente", type: 2 });
                break;
            case 3:
                settypeSearch({ ...typeSearch, tittle: "Nome cliente", type: 3 });
                break;
        }
    };
    const onClickStatus = (title, value) => {
        setTypeStatus({ title: title, status: value });
    };

    const ColoredLine = ({ color }) => (
        <hr
            style={{
                color: color,
                backgroundColor: color,
                height: 2,
                width: '100%',
                margin: 4
            }}
        />
    );

    const degrade = {
        // borderBottom: '1px solid transparent',
        borderImage: 'linear-gradient(to right, #d90059, #47a5db)',
        borderImageSlice: 1
    };

    const gradientBackground = {
        background: 'linear-gradient(to right, #d90059, #47a5db)'
    };

    const inputStyles = {
        outline: 'none', // Remover a borda padrão
        borderRadius: '3px',
    };

    const dropStyles = {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        maxWidth: '100%',
        width: '100%',

    };
    const btnInc = {
        borderWidth: '1px',
        borderStyle: 'solid',
        fontWeight: 600,
        borderImage: 'linear-gradient(to right, #d9005c, rgb(71, 165, 219)) 1 / 1 / 0 stretch',
        color: '#d9005c'
    };

    return (
        // <div className="flex flex-col items-center pt-16 h-screen">

        //     <div className="w-full lg:w-4/6">
        <div className="container">

            <div className="row">
                <div className="col-sm-6">
                    <p className="fw-semibold fs-2 m-0">
                        Cotações
                    </p>
                </div>
                <div className="col-md-3 d-flex align-items-center p-1 justify-center" >
                    <Dropdown className=''>
                        <Dropdown.Toggle variant="Secondary" id="dropdown-basic-stats" className=''>
                            Status: {typeStatus.title}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item eventKey={4} onClick={() => onClickStatus("Todos", "")}>Todos</Dropdown.Item>
                            <Dropdown.Item eventKey={0} onClick={() => onClickStatus("Aguardando", 0)}><div className="flex flex-row align-middle text-center"><div className="flex w-4 h-4 bg-gray-400 rounded-full self-center mr-3" />Aguardando</div></Dropdown.Item>
                            <Dropdown.Item eventKey={1} onClick={() => onClickStatus("Fila integração", 1)}><div className="flex flex-row align-middle text-center"><div className="flex w-4 h-4 bg-blue-400 rounded-full self-center mr-3" />Fila integração</div></Dropdown.Item>
                            <Dropdown.Item eventKey={2} onClick={() => onClickStatus("Aprovado", 2)}><div className="flex flex-row align-middle text-center"><div className="flex w-4 h-4 bg-green-400 rounded-full self-center mr-3" />Aprovado</div></Dropdown.Item>
                            <Dropdown.Item eventKey={3} onClick={() => onClickStatus("Reprovado", 3)}><div className="flex flex-row align-middle text-center"><div className="flex w-4 h-4 bg-yellow-400 rounded-full self-center mr-3" />Reprovado</div></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

                <div className="col-md-3 d-flex align-items-center p-1">

                    <Dropdown className=''>
                        <Dropdown.Toggle variant="Secondary" id="dropdown-basic" className='px-05'>
                            {typeSearch.tittle}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item eventKey={1} onClick={() => onClick(1)}>Número cotação</Dropdown.Item>
                            <Dropdown.Item eventKey={2} onClick={() => onClick(2)}>Código cliente</Dropdown.Item>
                            <Dropdown.Item eventKey={3} onClick={() => onClick(3)}>Nome Cliente</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <input type="text" id="table-search" className=" outline-gray-300 p-2 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-r-lg" placeholder="Pesquisar..." onChange={(event) => searchTerm(event.target.value.toUpperCase(), 0)} style={inputStyles} />

                </div>

            </div>
            <ColoredLine color="#4ba3de" />
            {/* <Accordion flush className="w-full   overflow-x-auto overflow-y-auto "> */}
            <Accordion flush className="w-100">
                {dataPedido.map((item, key) => {
                    return (
                        <AccordionPedidos item={item} key={key} />
                    )
                })}
            </Accordion>

            <div className="container" >
                <div className='row justify-end my-2'>
                    {page > 0 &&
                        <div className='col-md-2'>
                            <Button variant="" style={btnInc} size="sm" className='w-100 px-3 d-flex align-items-center justify-content-center rounded btn' onClick={() => setPage(page - 1)}>
                                <IoRemove />
                                Anterior
                            </Button>{' '}
                        </div>
                    }
                    {hasNext &&
                        <div className='col-md-2'>
                            <Button variant="" style={btnInc} size="sm" className='w-100 px-3 d-flex align-items-center justify-content-center rounded btn' onClick={() => setPage(page + 1)}>
                                <IoAdd />
                                Próxima
                            </Button>{' '}
                        </div>
                    }
                </div>
            </div>
            <div className="fixed bottom-8 right-8 z-10">
                <button style={gradientBackground} className="bg-blue-500 text-white p-4 rounded-full flex items-center justify-center space-x-2" onClick={() => navigate("/orcamentos/incluir")}>
                    <MdAdd size='30' />
                </button>
            </div>
        </div>
    )
}



export default Orcamento



const AccordionPedidos = ({ item }) => {
    const [dataCliente, setdataCliente] = useState([])
    const [itemPedido, setItemPedido] = useState([]);
    const { dataPedido, page, hasNext } = useSelector(state => state.pedidoReduce)
    // const { selectCli } = useSelector(state => state.clienteReduce)
    const { dataUser } = useSelector(state => state.userReduce)
    const { dataCondPag, selectCondPagamento } = useSelector(state => state.condPagReduce)
    const [show, setShow] = useState(false);
    const [showCheck, setShowCheck] = useState({});
    const [toEmail, setToEmail] = useState("");
    const [totalCarrrinho, setTotalCarrrinho] = useState(0);
    const [loadingEmail, setLoadingEmail] = useState(false);
    const [erroMassage, setErroMassage] = useState({});
    const [detPedido, setDetPedido] = useState({detCondPag:"", detTransp:""});
    let codigoPedido = item.codPedido.substring(3,item.codPedido.length)
    const dispatch = useDispatch()
    const navigate = useNavigate();

    const getDetPedido = async () => {
        await api
        .get("/condpagamentos", {headers:{Authorization:`Bearer ${checkToken()}`}, params: {idErpCondPag: dataPedido[0].condPagamento, 'filter': '3'}})
        .then((response) => setDetPedido((currentData) => ({...currentData, detCondPag: response.data[0]})));
        // .then((response) => console.log(response.data[0]));

     
        await api
        .get("/transportadoras", {headers:{Authorization:`Bearer ${checkToken()}`}, params: {idErpTransp: dataPedido[0].codTransportadora , 'filter': '3'}})
        .then((response) => setDetPedido((currentData) => ({...currentData, detTransp: response.data[0]})));
    }
    const styles = StyleSheet.create({
        page: {
          flexDirection: 'column',
          backgroundColor: '#E4E4E4'
        },
        header:{
            display: 'flex',
            width: '100%',
            flexDirection:"row",
            justifyContent: "space-between",

        },
        tableHeader: {
            flexDirection: 'row',
            width: '100%',
            borderBottom:"0.5",
            borderBottomColor:"gray"
        },
        section: {
          margin: "2 2",
          padding: 1,
          flexGrow: 1,

        },
        titulo: {
            fontSize: 20,
            textAlign: "center",
            margin: 10,
        },
        texto: {
            margin: 5,
            fontSize: 15
        },
        textTbHeader: {
            margin: 5,
            fontSize: 12
        },
        textTbRow: {
            margin: 5,
            fontSize: 10
        },
        tableRow: {
            display: 'flex',
            width: '100%',
        },
        table: {
            display: 'flex',
            width: '100%',
            border: 0.5,
            marginTop: '20'
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            marginVertical: "0",
            paddingVertical: "0",
            borderBottom:"0.5",
            borderBottomColor:"gray"
        },
        logo:{
            width: '30%',

        },
      });
      
    const MyDoc = () => {
        const converterData = (data) => {
            var ano = data.substring(0, 4);
            var mes = data.substring(4, 6);
            var dia = data.substring(6, 8);

            return dia + '/' + mes + '/' + ano.substring(2, 4);
        }
        let dataFormatada = converterData(item.emissao);
        return(
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.section}>
                        <View style={styles.header}>
                            <Image style={styles.logo} src={logo}/>
                            <Image style={styles.logo} src={logoPromexMais}/>
                        </View>
                        <Text style={styles.titulo}>Cotação: {codigoPedido} || Revisão: {item.revisao}</Text>
                        <Text style={styles.texto}>Representante: {dataUser[0].nmVendedor}</Text><br/>
                        <Text style={styles.texto}>Emiss&atilde;o:&nbsp; {dataFormatada}</Text>
                        <Text style={styles.texto}>Condição de pagamento: {detPedido.detCondPag.nmCondPag}</Text>
                        <Text style={styles.texto}>Transportadora: {detPedido.detTransp.nmTransp}</Text>
                        
                        <View style={styles.table}>
                            <View style={styles.tableHeader}>
                                <View style={{flex: .1}}><Text style={styles.textTbHeader}>#</Text><br/></View>
                                <View style={{flex: .2}}><Text style={styles.textTbHeader}>Código</Text><br/></View>
                                <View style={{flex: .4}}><Text style={styles.textTbHeader}>Produto</Text><br/></View>
                                <View style={{flex: .1}}><Text style={styles.textTbHeader}>Qtd.</Text><br/></View>
                                <View style={{flex: .2}}><Text style={styles.textTbHeader}>Preço(R$)</Text><br/></View>
                                <View style={{flex: .2}}><Text style={styles.textTbHeader}>Desc.(R$)</Text><br/></View>
                                <View style={{flex: .2}}><Text style={styles.textTbHeader}>Ipi</Text><br/></View>
                                <View style={{flex: .3}}><Text style={styles.textTbHeader}>Total(R$)</Text><br/></View>
                            </View>
                            <View style={styles.tableRow}>
                                {itemPedido.map((itemProd, index) => 
                                <View style={styles.row} key={index}>
                                    <View style={{flex: .1}}><Text style={styles.textTbRow}>{index}</Text></View>
                                    <View style={{flex: .2}}><Text style={styles.textTbRow}>{itemProd.codProduto}</Text></View>
                                    <View style={{flex: .4}}><Text style={styles.textTbRow}>{itemProd.nmProduto}</Text></View>
                                    <View style={{flex: .1}}><Text style={styles.textTbRow}>{itemProd.quantidade}</Text></View>
                                    <View style={{flex: .2}}><Text style={styles.textTbRow}>{convertDecimal(itemProd.preco.toFixed(2))}</Text></View>
                                    <View style={{flex: .2}}><Text style={styles.textTbRow}>{convertDecimal(itemProd.desconto.toFixed(2))}</Text></View>
                                    <View style={{flex: .2}}><Text style={styles.textTbRow}>{itemProd.ipi}</Text></View>
                                    <View style={{flex: .3}}><Text style={styles.textTbRow}>{convertDecimal((((itemProd.quantidade * itemProd.preco) - itemProd.desconto)*((itemProd.ipi/100)+1)).toFixed(2))}</Text></View>
                                </View>)}
                            </View>
                            <View style={styles.tableRow}>
                                <View style={styles.row}>
                                    <View style={{flex: 1}}><Text style={{margin: 5, fontSize: 12 ,fontWeight:"bold"}}>Total</Text></View>
                                    <View style={{flex: .18}}><Text style={{margin: 5, fontSize: 12 ,fontWeight:"bold"}}>R$ {convertDecimal(totalCarrrinho.toFixed(2))}</Text></View>
                                </View>
                            </View>
                        </View>
                    </View>
                    {/* Código	Produto	UM	Coleção	Qtd.	Preço	Desconto	Total */}
                   
                </Page>
            </Document>
        )
    };

    const enviaEmail = async () => {
        setLoadingEmail(true)
        const converterData = (data) => {
            var ano = data.substring(0, 4);
            var mes = data.substring(4, 6);
            var dia = data.substring(6, 8);

            return dia + '/' + mes + '/' + ano.substring(2, 4);
        }
        let dataFormatada = converterData(item.emissao);
        const formToPDF = {
            representante: dataUser[0].nmVendedor,
            cotacao: codigoPedido,
            revisao: item.revisao,
            emissao: dataFormatada,
            condPagamento: detPedido.detCondPag.nmCondPag,
            transportadora: detPedido.detTransp.nmTransp,
            itens: itemPedido
        }

        const htmlbody = `
        <html>
            <head>
                <style>
                    table {
                        font-family: arial, sans-serif;
                        border-collapse: collapse;
                        width: 100%;
                    }

                    td, th {
                        border: 1px solid #dddddd;
                        text-align: left;
                        padding: 8px;
                    }

                    tr:nth-child(even) {
                        background-color: #dddddd;
                    }
                </style>
            </head>
            <body>
                <div >
                    <p>Olá ${dataCliente.nmCliente},</p>
                    <p>Abaixo cotação enviado pelo representante:</p><br/>
                        <p>Cotação: ${codigoPedido} || Revisão: ${item.revisao}</p>
                        <p>Emiss&atilde;o:&nbsp; ${dataFormatada}</p>
                        <table>
                            <thead>
                                <tr>
                                    <th scope="col">Código</th>
                                    <th scope="col">Produto</th>
                                    <th scope="col">Qtd.</th>
                                    <th scope="col">Valor</th>
                                    <th scope="col">Desc.</th>
                                    <th scope="col">Ipi</th>
                                    <th scope="col">Total</th>
                                    <th scope="col">Pedido de compra</th>
                                </tr>
                            </thead>
                            <tbody>
                                ${itemPedido.map((x) => {
            return (
                `<tr>
                                            <td>${x.codProduto}</td>
                                            <td>${x.nmProduto}</td>
                                            <td>${x.quantidade}</td>
                                            <td>${convertDecimal(x.preco.toFixed(2))}</td>
                                            <td>${convertDecimal(x.desconto.toFixed(2))}</td>
                                            <td>${x.ipi}</td>
                                            <td>${convertDecimal((((x.quantidade * x.preco) - x.desconto)*((x.ipi/100)+1)).toFixed(2))}</td>
                                            <td>${x.pedido}</td>
                                        </tr>
                                        `
            )
        }).join('')}
                            </tbody>
                        </table>
                    <span><b>Total: R$ ${convertDecimal(totalCarrrinho.toFixed(2))}</b></span><br/>
                    <p>Atenciosamente, TovCare.</p><br/>
                </div>
            </body>
        </html>`
        try{
            await api.post('/email/send_email', { from_email: 'Pedidos Promex Mais<wf.pedidos@promexmais.com.br>', to_email: toEmail, subject: `Cotação - ${codigoPedido}`, html_body: htmlbody, detPedido: formToPDF  })
                .then(() => setLoadingEmail(false)).then(() => setShow(false))
            setErroMassage({...erroMassage, email:""})
        }catch (e){
            setLoadingEmail(false)
            setErroMassage({...erroMassage, email:"A falha no envio do email. Por favor, verifique sua conexão e tente novamente."})
        }
    }

    // const statusPedidos = async (status) => {
    //     switch (status) {
    //         case 'aprovado':
    //             dispatch(putPedido({ ...item, ativo: '1' }, window.location.reload(false)))
    //             break
    //         case 'cancelado':
    //             dispatch(putPedido({ ...item, ativo: '4' }, window.location.reload(false)))
    //             break
    //         case 'reprovado':
    //             dispatch(putPedido({ ...item, ativo: '3' }, window.location.reload(false)))
    //             break
    //     }
    // }
    const statusPedidos = (status) => {
        let updatedItem = { ...item };
        switch (status) {
            case 'aprovado':
                updatedItem.ativo = '1';
                dispatch(putPedido(updatedItem, window.location.reload(false)));
                break;
            case 'cancelado':
                updatedItem.ativo = '4';
                dispatch(putPedido(updatedItem, window.location.reload(false)));
                break;
            case 'reprovado':
                updatedItem.ativo = '3';
                dispatch(putPedido(updatedItem, window.location.reload(false)));
                break;
            default:
                break;
        }
    }

    const getCliente = async () => {
        await api.get("/clientes", {headers:{Authorization:`Bearer ${checkToken()}`}, params: { "idCliente": item.codCliente } }).then((response) => { setdataCliente(response.data[0]); setToEmail(response.data[0].emailCliente) })
    }

    const getItem = async (idPedido, revisao) => {
        const params = new URLSearchParams([['idPedido', idPedido], ['revisao', revisao]]);
        let response = await api.get('/pedidos/itens', {headers:{Authorization:`Bearer ${checkToken()}`}, params })
        setItemPedido(response.data)
        let result = 0
        response.data.map((item) => {
            result = result + (((item.preco * item.quantidade) - item.desconto) * ((item.ipi/100)+1))
            return result
        })
        setTotalCarrrinho(result)
    }

    useEffect(() => {
        getCliente();
        getDetPedido() 
        getItem(item.codPedido, item.revisao);
        // TotalCarrinho()
    }, [dataPedido]);

    const ItemOrcamento = () => {
        return (
            <div>
                <Table responsive>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Código</th>
                            <th>Produto</th>
                            <th>UM</th>
                            <th>Coleção</th>
                            <th>Qtd.</th>
                            <th>Preço</th>
                            <th>Desconto</th>
                            <th>Ipi</th>
                            <th>Total</th>


                        </tr>
                    </thead>
                    <tbody>
                        {itemPedido.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.codProduto}</td>
                                    <td>{item.nmProduto}</td>
                                    <td>{item.um}</td>
                                    <td>{item.codColecao}</td>
                                    <td>{item.quantidade}</td>
                                    <td>{convertDecimal(item.preco.toFixed(2))}</td>
                                    <td>{convertDecimal(item.desconto.toFixed(2))}</td>
                                    <td>{item.ipi}</td>
                                    <td>{convertDecimal((((item.quantidade * item.preco) - item.desconto)*((item.ipi/100)+1)).toFixed(2))}</td>

                                </tr>
                            )
                        })
                        }
                    </tbody>

                </Table>
                {/* <TotalCarrinho/> */}
                <div className="flex w-full justify-end">
                    <span className="font-bold">Total: R$ {convertDecimal(totalCarrrinho.toFixed(2))}</span>
                </div>
            </div>
        )
    }

    const gerarNovaRevisao = async (itemPedido) => {
        dispatch(postRevisaoCarrinho(itemPedido))
        navigate("/orcamentos/editar", { state: { idCliente: dataCliente.codCliente, dataOrcamento: item, itemPedido: itemPedido } })
    }

    const ModalCheck = ({openModel ,header, check}) => {
        return(
                <Modal show={openModel} onHide={() => setShowCheck({open:false})} aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
                    <Modal.Header closeButton className="bg-yellow-500">
                        <Modal.Title>{header}</Modal.Title>
                    </Modal.Header>
                    {/* <Modal.Body >
                        <FloatingLabel controlId="floatingInput" label="Email" className="my-2">
                            <Form.Control value={toEmail} as="input" onChange={e => setToEmail(e.target.value)} />
                        </FloatingLabel>
                    </Modal.Body> */}
                    <Modal.Footer>
                        <Button disabled={loadingEmail ? true : false} variant="secondary" onClick={() => check()}>
                            {loadingEmail ? <LoadingIcon /> : 'Confirmar'}
                        </Button>
                        <Button variant="secondary" onClick={() => setShowCheck({open:false})}>
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
        )
    }

    return (
        <Accordion.Item key={item.filial + item.codPedido + item.revisao} eventKey={item.filial + item.codPedido + item.revisao}>
            <Accordion.Header >
                {item.ativo === '0' && <div className="flex w-4 h-12 bg-gray-400 rounded-full mx-2 md:w-3 md:h-3" />}
                {item.ativo === '1' && <div className="flex w-4 h-12 bg-blue-400 rounded-full mx-2 md:w-3 md:h-3" />}
                {item.ativo === '2' && <div className="flex w-4 h-12 bg-green-400 rounded-full mx-2 md:w-3 md:h-3" />}
                {item.ativo === '3' && <div className="flex w-4 h-12 bg-yellow-400 rounded-full mx-2 md:w-3 md:h-3" />}
                <div className="grid justify-items-stretch w-full">
                    <p className="text-sm mr-2 my-0"><b>Cotação:</b> {codigoPedido} - <b>Versão:</b> {item.revisao} </p>
                    <p className="text-sm mr-2 my-0"><b>Cliente:</b> {dataCliente.nmCliente} <b>(</b>{dataCliente.codCliente}<b>)</b></p>
                </div>
            </Accordion.Header>
            <Accordion.Body style={{ overflow: "auto" }} >
                <div className="flex md:flex-row flex-col md:justify-between w-full">
                    <div>
                        <p className="text-sm mr-2 my-0"> <b>E-mail:</b> {dataCliente.emailCliente}</p>
                        <p className="text-sm mr-2 my-0"> <b>Emissão:</b> {converterData(item.emissao)} {item.ativo === '2' ? <><b>||</b> <b>Integração:</b> {converterData(item.emissao)}</> : ""} </p>
                        {item.ativo === '2' && <p className="text-sm mr-2 my-0"><b>Número Orçamento: </b>{item.orcamento} </p>}
                    </div>


                </div>
                <ItemOrcamento item={item} key={item.codPedido} />

                <div className="container pt-3">
                    <div className='row justify-end'>

                        {item.ativo === '0' &&
                            <div className='col-sm-2 m-1 px-1'>
                                <Button variant="success" size="sm" className='w-100 d-flex align-items-center justify-content-center' onClick={() => setShowCheck({open: true, tittleModel:`Deseja aprovar cotação ${codigoPedido}?`, type:"aprovado"})}>
                                    <IoCheckmarkSharp className='mr-1' />
                                    Aprovar
                                </Button>
                            </div>
                        }
                        {(item.ativo === '0') &&
                            <div className='col-sm  m-1 px-1 '>
                                <Button variant="secondary " size="sm" className='w-100 d-flex align-items-center justify-content-center' onClick={() => setShowCheck({open: true, tittleModel:`Deseja reprovar cotação ${codigoPedido}?`, type:"reprovado"})}>
                                    <IoClose className='mr-1' />
                                    Reprovar
                                </Button>
                            </div>
                        }
                        {(item.ativo === '0' || item.ativo === '4') &&
                            <div className='col-sm m-1 px-1 '>
                                <Button variant="danger" size="sm" className='w-100 d-flex align-items-center justify-content-center' onClick={() => setShowCheck({open: true, tittleModel:`Deseja cancelar cotação ${codigoPedido}?`, type:"cancelado"})}>
                                    <IoClose className='mr-1' />
                                    Cancelar
                                </Button>
                            </div>
                        }
                        {(item.ativo === '0' || item.ativo === '4') &&
                            <div className='col-sm  m-1 px-1 '>
                                <Button variant="warning" size="sm" className='w-100 d-flex align-items-center justify-content-center' onClick={() => gerarNovaRevisao(itemPedido)}>
                                    <IoPencil className='mr-1' />
                                    Gerar nova versão
                                </Button>
                            </div>
                        }

                        {(item.ativo === '0' || item.ativo === '1') &&
                            <div className='col-sm  m-1 px-1 '>
                                <Button variant="primary" size="sm" className='w-100 d-flex align-items-center justify-content-center' onClick={() => setShow(true)}>
                                    <IoMailOpenOutline className='mr-1' />
                                    Enviar por E-mail
                                </Button>
                            </div>
                        }
                        {(item.ativo === '0' || item.ativo === '1') &&
                            <div className='col-sm m-1 px-1 '>
                                <PDFDownloadLink document={<MyDoc />} fileName={`cotacao${codigoPedido}-${item.revisao}.pdf`} >
                                {({ blob, url, loading, error }) => 
                                    <Button variant="primary" size="sm" disabled={loading} className='w-100 d-flex align-items-center justify-content-center'> 
                                        <IoDownload className='mr-1' />
                                        {(loading ? 'Baixando...' : 'Baixar PDF')}
                                    </Button> }
                                </PDFDownloadLink>
                            </div>
                        }
                    </div>
                </div>
            </Accordion.Body>
            <div className="modal show">
                <Modal show={show} onHide={() => setShow(false)} aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
                    <Modal.Header closeButton className="bg-yellow-500">
                        <Modal.Title>Enviar {item.codPedido ? "Cotação "+codigoPedido+" - Revisão "+item.revisao: ""}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <FloatingLabel controlId="floatingInput" label="Email" className="my-2">
                            <Form.Control value={toEmail} as="input" onChange={e => setToEmail(e.target.value)} />
                        </FloatingLabel>
                        {erroMassage.email && <p className="text-xs text-red-500">{erroMassage.email}</p>}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button disabled={loadingEmail ? true : false}  variant="success" onClick={() => enviaEmail()}>
                            {loadingEmail ?  'Enviando...' : 'Enviar'}
                        </Button>
                        <Button variant="danger" onClick={() => setShow(false)}>
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
                <ModalCheck openModel={showCheck.open} header={showCheck.tittleModel} check={() => statusPedidos(`${showCheck.type}`)}/>
            </div>
        </Accordion.Item>
    )
}
