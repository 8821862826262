// import React, { useEffect, useState } from "react";
import React, { useEffect, useState , Component} from 'react';
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { selectCondPag, getCondpag} from '../../store/CondPagamento';
import Select, {components} from 'react-select';
const { ValueContainer, Placeholder } = components;


const SelectCondPagamento = ({initialValue}) => {
  const { optionsSelect } = useSelector(state => state.condPagReduce)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCondpag())
    if(initialValue){ 
      dispatch(selectCondPag(initialValue))
    }
  },[dispatch]);


  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

  return(
    // <div className="flex flex-row bg-slate-200 w-full">
      <div className="w-full">
          <Select
            // className="react-select-containe"
            defaultValue={optionsSelect[optionsSelect.findIndex((element) => element.value == initialValue)]}
            onChange={(e) => dispatch(selectCondPag(e.value))}
            isClearable={true}
            isSearchable={true}
            options={optionsSelect}
            components={{
              ValueContainer: CustomValueContainer
            }}
            placeholder="Condição de Pagamento"
            styles={{
              container: (provided, state) => ({
                ...provided,
                marginTop: 10
              }),
              valueContainer: (provided, state) => ({
                ...provided,
                overflow: "visible",
                marginTop: 20
              }),
              placeholder: (provided, state) => ({
                ...provided,
                position: "absolute",
                // top: state.hasValue || state.selectProps.inputValue ? -3 : "50%",
                top: -15 ,

                // backgroundColor:
                //   state.hasValue || state.selectProps.inputValue
                //     ? "white"
                //     : "transparent",
                backgroundColor: "transparent",
                // transition: "top 0.1s, font-size 0.1s",
                fontSize: 14
              })
            }}
          />
      </div>
    // </div>
  )
}


export default SelectCondPagamento;