export function converterData(data) {
    // Extrair o ano, mês e dia da string data
    var ano = data.substring(0, 4);
    var mes = data.substring(4, 6);
    var dia = data.substring(6, 8);

    // Formatar a data no formato desejado
    var dataFormatada = dia + '/' + mes + '/' + ano;

    return dataFormatada;
}


