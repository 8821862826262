import { createSlice } from '@reduxjs/toolkit'
import api from "../services/api";
import { checkToken } from "./Usuarios"

const initialTransp = []

const transpReduce = createSlice({
    name: 'transportadora',
    initialState: {
      dataTransp: initialTransp,
      selectTransportadora:{},
      selectTipoTransportadora:{},
      optionsSelect: [],
      loading: true
    },
    reducers: {
      fetchTransp: (state, action) => {
        state.dataTransp = action.payload;
        let options = []
        action.payload.map((item)=>{
          options.push({label: item.nmTransp, value: item.idErpTransp})
          return null
        })
        state.optionsSelect = options
        // localStorage.setItem('dataTransp', JSON.stringify(action.payload))
      },

      setTransp: (state, action) => {
        state.selectTransportadora = action.payload;
        // localStorage.setItem("selectCli", JSON.stringify(action.payload));
      },

      setTipoTransp: (state, action) => {
        state.selectTipoTransportadora = action.payload;
        // localStorage.setItem("selectCli", JSON.stringify(action.payload));
      },
      
      addTransp: (state, action) => {
        state.dataTransp = [...state.dataTransp, action.payload];
        // localStorage.setItem('dataTransp', JSON.stringify(action.payload))
      },

      editTransp: (state, action) => {
        let index = state.dataTransp.findIndex(x => x.id === action.id)
        state.dataTransp[index] = action.payload
          // localStorage.setItem('dataTransp', JSON.stringify(action.payload))
      },
      
      delTransp: (state) => {
        state.selectTransportadora = {}
        // localStorage.setItem('dataTransp', JSON.stringify(action.payload))
      }
    },
  })

  export default transpReduce.reducer


  ///////////////////////////  Actions ///////////////////////////////

const { fetchTransp, setTransp, setTipoTransp, addTransp, editTransp, delTransp } = transpReduce.actions
 
export const getTransp = () => async dispatch => {
    try {
      // if(id){
      //   const params = new URLSearchParams([['limit', 10],['idErpTransp', id]]);
      //   await api.get('/transportadoras', {params})
      //       .then((response) => dispatch(setTransp(response.data[0])))
      // }else {
        // const params = new URLSearchParams([['filter', '1']]);
        await api.get('/transportadoras', {headers:{Authorization:`Bearer ${checkToken()}`}, params: {filter: '3'}})
          .then((response) => dispatch(fetchTransp(response.data)))
      // }
    }
    catch (e) {
        return console.error(e.message);
    }
}

export const selectTransp = (idErpTransp) => async dispatch => {
  try {
    // await dispatch(setTransp(JSON.parse(form)))
    await api
      .get("/transportadoras", {headers:{Authorization:`Bearer ${checkToken()}`}, params: {idErpTransp, 'filter': '3'}})
      .then((response) => dispatch(setTransp(response.data[0])));
  }
  catch (e) {
      return console.error(e.message);
  }
}

export const selectTipoTransp = (form) => async dispatch => {
  try {
    await dispatch(setTipoTransp(JSON.parse(form)))
  }
  catch (e) {
      return console.error(e.message);
  }
}

export const postTransp = (form, cb) => async dispatch => {
  try {
    // const params = new URLSearchParams([['limit', 10]]);
    await api.post('/transportadoras', form, {headers:{Authorization:`Bearer ${checkToken()}`}})
        .then((response) => dispatch(addTransp(response.data))).then(cb)
  }
  catch (e) {
      return console.error(e.message);
  }
}

export const putTransp = (form,cb) => async dispatch => {
  try {
    const params = new URLSearchParams([['id', form.id]]);
    await api.put(`/transportadoras/${form.id}`, form, {headers:{Authorization:`Bearer ${checkToken()}`}, params})
        .then((response) => dispatch(editTransp(response.data))).then(cb)
  }
  catch (e) {
      return console.error(e.message);
  }
}

export const deleteTransp = () => async dispatch => {
  try {
    await dispatch(delTransp())
  }
  catch (e) {
      return console.error(e.message);
  }
}

// export const selectTransp = (state) => state.transportadora