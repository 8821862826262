import { createSlice } from '@reduxjs/toolkit'
import api from "../services/api";


const initialCarrinho = localStorage.getItem("dataCarrinho")
  ? JSON.parse(localStorage.getItem("dataCarrinho"))
  : [];
const carrinhoReduce = createSlice({
    name: 'carrinho',
    initialState: {
      dataCarrinho: initialCarrinho,
      isLoading: false
      //initialCarrinho
    },
    reducers: {
      fetchCarrinho: (state, action) => {
        state.dataCarrinho = localStorage.getItem("dataCarrinho")
        ? JSON.parse(localStorage.getItem("dataCarrinho"))
        : [];
        },

      addCarrinho: (state, action) => {
        state.dataCarrinho = [...state.dataCarrinho, action.payload];
        localStorage.setItem('dataCarrinho', JSON.stringify(state.dataCarrinho))
        // localStorage.setItem('dataCarrinho', [...state.dataCarrinho, JSON.stringify(action.payload)])

        },

      editCarrinho: (state, action) => {
        let aux = JSON.parse(localStorage.getItem("dataCarrinho"))
        let result = aux.map((obj, index) => action.payload.id === index ? obj = action.payload : obj)
        // state.dataCarrinho[action.payload.id] = action.payload
        localStorage.setItem('dataCarrinho', JSON.stringify(result))
      },

      addRevisaoCarrinho: (state, action) => {
        // localStorage.setItem('dataCarrinho', JSON.stringify([]))
        localStorage.setItem('dataCarrinho', JSON.stringify(action.payload))
    

    },

      
      delCarrinho: (state, action) => {
        let aux = JSON.parse(localStorage.getItem("dataCarrinho"))
        let result = aux.splice(action.payload, 1)
        localStorage.setItem('dataCarrinho', JSON.stringify(aux))
      },

      clearCarrinho: (state) => {
        localStorage.setItem('dataCarrinho', JSON.stringify([]))
      },

      setIsLoading: (state, action) => {
        state.isLoading = action.payload

      }
    },
  })

  export default carrinhoReduce.reducer


  ///////////////////////////  Actions ///////////////////////////////

const { fetchCarrinho, addCarrinho, editCarrinho, addRevisaoCarrinho, delCarrinho, clearCarrinho, setIsLoading } = carrinhoReduce.actions
 
export const getCarrinho = () => async dispatch => {
    try {
      // const params = new URLSearchParams([['limit', 10]]);
      // await api.get('/carrinhos', { params: {filial, codCliente, codVendedor} })
      //     .then((response) => dispatch(fetchCarrinho(response.data)))
      dispatch(fetchCarrinho())
    }
    catch (e) {
        return console.error(e.message);
    }
}

export const postCarrinho = (form) => async dispatch => {
  try {
    // const params = new URLSearchParams([['idFilial', '1'],['idUsuario', '1']]);
    // await api.post('/carrinhos', form, { params: {filial, codCliente, codVendedor} })
    //     .then((response) => {dispatch(addCarrinho(form))})
    dispatch(addCarrinho(form))
  }
  catch (e) {
      return console.error(e.message);
  }
}

export const postRevisaoCarrinho = (form) => async dispatch => {
  try {
    // const params = new URLSearchParams([['idFilial', '1'],['idUsuario', '1']]);
    // await api.post('/carrinhos', form, { params: {filial, codCliente, codVendedor} })
    //     .then((response) => {dispatch(addCarrinho(form))})
    dispatch(addRevisaoCarrinho(form))
  }
  catch (e) {
      return console.error(e.message);
  }
}

export const putCarrinho = (form) => async dispatch => {
  try {
    await dispatch(editCarrinho(form))
  }
  catch (e) {
    return console.error(e.message);
  }
}

export const deleteCarrinho = (index, allClear = false) => async dispatch => {
  try {
    allClear ? dispatch(clearCarrinho()) : dispatch(delCarrinho(index))
  }
  catch (e) {
      return console.error(e.message);
  }
}


export const setLoading = (load = false) => async dispatch => {
  try {
    dispatch(setIsLoading(load))
  }
  catch (e) {
      return console.error(e.message);
  }
}