import axios from "axios";


axios.defaults.headers.post['Content-Type'] = 'application/json';
// axios.defaults.headers.common['ngrok-skip-browser-warning'] = '69420';

const api = axios.create({
    baseURL: 'https://mercury.promexmais.com.br:8096',
    // baseURL: 'http://10.111.70.6:8096',

});

export default api;
