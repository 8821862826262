import { createSlice } from '@reduxjs/toolkit'
import api from "../services/api";
import { checkToken } from "./Usuarios"


const initialCondPagamento = []

const condPagReduce = createSlice({
    name: 'condPagamento',
    initialState: {
      dataCondPag: initialCondPagamento,
      selectCondPagamento: {},
      optionsSelect: [],
      loading: true
    },
    reducers: {
      fetchCondPag: (state, action) => {
        state.dataCondPag = action.payload;
        let options = []
        action.payload.map((item)=>{
          options.push({label: item.nmCondPag, value: item.idErpCondPag})
          return null
        })
        state.optionsSelect = options
        state.loading = false;
        // localStorage.setItem('dataCondPag', JSON.stringify(action.payload))
        },

      setCondPag: (state, action) => {
        state.selectCondPagamento = action.payload;
        // localStorage.setItem("selectCli", JSON.stringify(action.payload));
      },

      addCondPag: (state, action) => {
        state.dataCondPag = [...state.dataCondPag, action.payload];
        // localStorage.setItem('dataCondPag', JSON.stringify(action.payload))
        },

      editCondPag: (state, action) => {
        let index = state.dataCondPag.findIndex(x => x.id === action.id)
        state.dataCondPag[index] = action.payload
          // localStorage.setItem('dataCondPag', JSON.stringify(action.payload))
        },
      
      delCondPag: (state) => {
        state.selectCondPagamento = {}
      }
    },
  })

  export default condPagReduce.reducer


  ///////////////////////////  Actions ///////////////////////////////

const { fetchCondPag, setCondPag, addCondPag, editCondPag, delCondPag } = condPagReduce.actions
 
export const getCondpag = () => async dispatch => {
    try {
        await api.get('/condpagamentos', {headers:{Authorization:`Bearer ${checkToken()}`}, params: {filter: '3'}})
        .then((response) => dispatch(fetchCondPag(response.data)))
      
    }
    catch (e) {
        return console.error(e.message);
    }
}

export const selectCondPag = (idErpCondPag) => async dispatch => {
  try {
    // await dispatch(setCondPag(JSON.parse(form)))
    await api
    .get("/condpagamentos", {headers:{Authorization:`Bearer ${checkToken()}`}, params: {idErpCondPag, 'filter': '3'}})
    .then((response) => dispatch(setCondPag(response.data[0])));
  }
  catch (e) {
      return console.error(e.message);
  }
}

// export const postCondpag = (form, cb) => async dispatch => {
//   try {
//     // const params = new URLSearchParams([['limit', 10]]);
//     await api.post('/condpagamentos', form)
//         .then((response) => dispatch(addCondPag(response.data))).then(cb)
//   }
//   catch (e) {
//       return console.error(e.message);
//   }
// }

// export const putCondpag = (form,cb) => async dispatch => {
//   try {
//     const params = new URLSearchParams([['id ', form.id]]);
//     await api.put(`/condpagamentos/${form.id}`, form, params)
//         .then((response) => dispatch(editCondPag(response.data))).then(cb)
//   }
//   catch (e) {
//       return console.error(e.message);
//   }
// }

export const deleteCondpag = () => async dispatch => {
  try {
    await dispatch(delCondPag())
  }
  catch (e) {
      return console.error(e.message);
  }
}