import { createSlice } from '@reduxjs/toolkit'
import api from "../services/api";
import { checkToken } from "./Usuarios"

const initialPedido = []
const initialPedidoItem = []


const pedidoReduce = createSlice({
    name: 'pedidos',
    initialState: {
      dataPedido: initialPedido,
      dataPedidoItem: initialPedidoItem,
      page:0,
      hasNext: false
    },
    reducers: {
      fetchPedido: (state, action) => {
        state.dataPedido = action.payload.pedidos;
        state.hasNext = action.payload.hasNext;
        state.page = action.payload.page;

        // localStorage.setItem('dataPedido', JSON.stringify(action.payload))
      },
      
      fetchPedidoItem: (state, action) => {
        state.dataPedidoItem = action.payload;
        // localStorage.setItem('dataPedido', JSON.stringify(action.payload))
      },
    },
  })

  export default pedidoReduce.reducer


  ///////////////////////////  Actions ///////////////////////////////

// const { fetchPedido, addPedido, editPedido, delPedido } = pedidoReduce.actions
const { fetchPedido, fetchPedidoItem } = pedidoReduce.actions

 
export const getPedido = (filial, idVendedor, typeStatus, typeSearch, page) => async dispatch => {
    let params;
    switch(typeSearch.type) {
      case 1:
        params = new URLSearchParams([['filial',filial], ['idVendedor', idVendedor], ['idPedido', typeSearch.term ], ['ativo', typeStatus.status], ['ultRevisao', 'S'], ['limit', 10], ['skip', page], ['filter', 'R_E_C_N_O_ desc']]);
        break;
      case 2:
        params = new URLSearchParams([['filial',filial], ['idVendedor', idVendedor], ['idCliente', typeSearch.term ], ['ativo', typeStatus.status], ['ultRevisao', 'S'], ['limit', 10], ['skip', page], ['filter', 'R_E_C_N_O_ desc']]);
        break;
      case 3:
        params = new URLSearchParams([['filial',filial], ['idVendedor', idVendedor], ['nmCliente', typeSearch.term ], ['ativo', typeStatus.status], ['ultRevisao', 'S'], ['limit', 10], ['skip', page], ['filter', 'R_E_C_N_O_ desc']]);
        break;
      default:
        params = new URLSearchParams([['filial',filial], ['idVendedor', idVendedor], ['ativo', typeStatus.status], ['ultRevisao', 'S'], ['limit', 10], ['skip', page], ['filter', 'R_E_C_N_O_ desc']]);
    }
    try {
      await api.get('/pedidos', { headers:{Authorization:`Bearer ${checkToken()}`}, params })
          .then((response) => dispatch(fetchPedido(response.data)))

    }
    catch (e) {
        return console.error(e.message);
    }
}

export const getPedidoItem = (id) => async dispatch => {
  try {
    const params = new URLSearchParams([['idPedido',id],['limit', 10]]);
    await api.get('/pedidos/itens', { headers:{Authorization:`Bearer ${checkToken()}`}, params })
        .then((response) => dispatch(fetchPedidoItem(response.data)))
  }
  catch (e) {
      return console.error(e.message);
  }
}

export const postPedido = async (form) => {
  try {
    // const params = new URLSearchParams([['limit', 10]]);
    let response =  await api.post('/pedidos', form, {headers:{Authorization:`Bearer ${checkToken()}`}})
    return response
        // .then((response) => {if(response.status === 200){return cb}})
        // .then((response) => dispatch(addPedido(response.data)))

  }
  catch (e) {
      return console.error(e.message);
  }
}

export const putPedido = async (form, cb) =>  {
  try {
    // const params = new URLSearchParams([['idPedido', form.codPedido]]);
    await api.put(`/pedidos/${form.codPedido}`, form, {headers:{Authorization:`Bearer ${checkToken()}`}})
    return cb
        // .then((response) => dispatch(editPedido(response.data))).then(cb)
  }
  catch (e) {
      return console.error(e.message);
  }
}

// export const deletePedido = (form,cb) => async dispatch => {
//   try {
//     const params = new URLSearchParams([['idPedido ', form.idPedido]]);
//     await api.delete(`/pedidos/${form.idPedido}`, form, params)
//         .then(() => dispatch(delPedido(form))).then(cb)
//   }
//   catch (e) {
//       return console.error(e.message);
//   }
// }