///////////////// HOOKS //////////////
import React, { useEffect, useState } from "react";
import { Accordion, Button, Modal, Form, Row, Col, InputGroup } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
///////////////// STORE //////////////
// import { getCliente } from '../../store/Clientes';
// import { getDispProd, getDispEst } from '../../store/Disponibilidade';
import { postCarrinho } from '../../../store/Carrinho';
import { getCarrinho, putCarrinho, deleteCarrinho } from '../../../store/Carrinho';
import { checkToken } from '../../../store/Usuarios'
import api from "../../../services/api";
///////////////// COMPONENTS //////////////
import { NumericFormat } from 'react-number-format';
import {convertDecimal} from '../../../function/maskInputMoney';
// import { Container, Accordion, Button, Modal, Form, Row, Col, InputGroup} from 'react-bootstrap';

const AddCarrinho = (props) => {
    const dispatch = useDispatch()
    const { select } = useSelector(state => state.filialReduce)
    const { selectCli } = useSelector(state => state.clienteReduce)
    const { selectTabelaPreco } = useSelector(state => state.precoReduce)

    const [precoProd, setPrecoProd] = useState([]);
    const [error, setError] = useState({ qtd: '', preco: '' });
    const [formProd, setFormProd] = useState({
        filial: select.idFilial,
        codCliente: selectCli.codCliente,
        codVendedor: selectCli.codVendedor,
        codProduto: props.item.codCor == 'UNI' ? props.item.idErpProduto : props.item.idErpProduto + "C" + props.item.codCor,
        nmProduto: props.item.nmProduto + "./" + props.item.nmCor,
        um: props.item.um,
        codColecao: "TC",
        quantidade: 0,
        preco: 0,
        desconto: 0,
        descontoPc: 0,
        ipi:props.item.ipi,
        pedido: "",
        ativo: '1'
    });

    useEffect(() => {
        getPreco()
        setError({ qtd: '' })
        setFormProd({ ...formProd, filial: select.idFilial, codVendedor: selectCli.codVendedor, codCliente: selectCli.codCliente, codProduto: props.item.codCor == 'UNI' ? props.item.idErpProduto : props.item.idErpProduto + "C" + props.item.codCor, nmProduto: props.item.nmProduto + "./" + props.item.nmCor, um: props.item.um, codColecao: "TC", quantidade: 0, ipi: props.item.ipi })
    }, [props.item, selectCli, selectTabelaPreco]);


    const getPreco = () => {
        const params = new URLSearchParams([['codProduto', props.item.idErpProduto], ['codTab', selectTabelaPreco.codTabPreco]]);
        api
            .get(`/precos/ProdPreco/`, { headers:{Authorization:`Bearer ${checkToken()}`}, params })
            .then((response) => setFormProd({ ...formProd, ['preco']: response.data[0].prodPreco }))
            // .then((response) => {setPrecoProd(response.data)})
            .catch((err) => {
                console.error("ops! ocorreu um erro" + err);
            });
    }

    const onChangeForm = (e) => {
        if (e.target.name === 'quantidade' && e.target.value > props.item.qtd) {
            setError({ qtd: "Quantidade indisponível" })
            setFormProd({ ...formProd, [e.target.name]: e.target.value, ['codProduto']: props.item.codCor == 'UNI' ? props.item.idErpProduto : props.item.idErpProduto + "C" + props.item.codCor, ['nmProduto']: props.item.nmProduto + "./" + props.item.nmCor, ['um']: props.item.um, ['ipi']: props.item.ipi })
            return
        }
        if (e.target.name === 'pedido') {
            setFormProd({ ...formProd, [e.target.name]: e.target.value, ['codProduto']: props.item.codCor == 'UNI' ? props.item.idErpProduto : props.item.idErpProduto + "C" + props.item.codCor, ['nmProduto']: props.item.nmProduto + "./" + props.item.nmCor, ['um']: props.item.um,  ['ipi']: props.item.ipi  })
            return
        }
        setError({ qtd: '' })
        setFormProd({ ...formProd, [e.target.name]: parseInt(e.target.value), ['codProduto']: props.item.codCor == 'UNI' ? props.item.idErpProduto : props.item.idErpProduto + "C" + props.item.codCor, ['nmProduto']: props.item.nmProduto + "./" + props.item.nmCor, ['um']: props.item.um,  ['desconto']: (formProd.descontoPc * (parseInt(e.target.value) * formProd.preco))/100,  ['ipi']: props.item.ipi  })

    }

    const postForm = () => {
        setFormProd({ ...formProd, codProduto:  props.item.codCor == 'UNI' ? props.item.idErpProduto : props.item.idErpProduto + "C" + props.item.codCor, nmProduto: props.item.nmProduto + "./" + props.item.nmCor, um: props.item.um, codColecao: props.item.colecao, ipi: props.item.ipi })
        const validacao = (form) => {
            let numErrors = 0
            let errorMessage = {}

            if (typeof form.quantidade === "undefined" || form.quantidade > props.item.qtd) {
                errorMessage.qtd = "Quantidade indisponível"
                numErrors++
            }
            if (form.quantidade <= 0 || Number.isNaN(form.quantidade)) {
                errorMessage.qtd = "Informar quantidade"
                numErrors++
            }
            if (typeof form.preco === "undefined" || form.preco <= 0) {
                errorMessage.preco = "Informar preço"
                numErrors++
            }
            if (typeof form.desconto === "undefined" || form.desconto >= (form.quantidade * form.preco)) {
                errorMessage.desconto = "Desconto inválido"
                numErrors++
            }
            if (form.descontoPc > 20) {
                errorMessage.desconto = "Não podem ser concedidos descontos acima de  20%"
                numErrors++
            }
            setError(errorMessage)
            if (numErrors > 0) {
                return false
            } else {
                return true
            }
        }
        let valid = validacao(formProd)
        if (valid) {
            dispatch(postCarrinho(formProd))
            setFormProd({
                filial: select.idFilial,
                codCliente: selectCli.codCliente,
                codVendedor: selectCli.codVendedor,
                codProduto: "",
                nmProduto: "",
                um: "",
                codColecao: "TC",
                quantidade: 0,
                preco: 0.00,
                pedido: "",
                desconto: 0.0,
                descontoPc: 0.0,
                ipi: 0,
                ativo: '1'
            })
            props.onHide()
        }
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h4>{props.item.nmProduto}</h4>
                    {/* <h6>{props.item.estoque[0] ? ('Coleção: '+ props.item.estoque[0].colecao +'  |  Quantidade: '+ props.item.estoque[0].colecao.quantidade) : null }</h6> */}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form name={props.prod_id} >
                    <div className="flex flex-col">
                        <div className="flex md:flex-row md:space-x-3 flex-col">
                            <div className="w-full">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Qtd. (max: {props.item.qtd})</Form.Label>
                                    <Form.Control value={formProd.quantidade} name="quantidade" onChange={onChangeForm} type="number" min="1" max={props.item.qtd} placeholder="0" />
                                </Form.Group>
                                {error.qtd && <p className="text-xs text-red-500">{error.qtd}</p>}
                            </div>
                            <div className="w-full">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Preço</Form.Label>
                                    <NumericFormat
                                        value={formProd.preco.toFixed(2)}
                                        decimalSeparator=","
                                        prefix="R$ "
                                        decimalScale={2}
                                        allowNegative={false}
                                        customInput={Form.Control}
                                        onValueChange={(e) => setFormProd({ ...formProd, ['preco']: e.floatValue || 0 , ['desconto']: (formProd.descontoPc * (formProd.quantidade * e.floatValue))/100})}
                                    />
                                </Form.Group>
                                {error.preco && <p className="text-xs text-red-500">{error.preco}</p>}
                            </div>
                            <div className="w-full">
                                <Form.Label>Desconto %</Form.Label>
                                <NumericFormat
                                    value={formProd.descontoPc}
                                    decimalSeparator=","
                                    decimalScale={5}
                                    allowNegative={false}
                                    customInput={Form.Control}
                                    onValueChange={(e) => setFormProd(currentForm => ({...currentForm, ['descontoPc']: e.floatValue || 0, ['desconto']: (e.floatValue * (formProd.quantidade * formProd.preco))/100}))}
                                />
                                {error.desconto && <p className="text-xs text-red-500">{error.desconto}</p>}
                            </div>
                            {/* <div className="w-full">
                                <Form.Label>Desconto R$</Form.Label>
                                <NumericFormat
                                    value={formProd.desconto.toFixed(2)}
                                    decimalSeparator=","
                                    prefix="R$ "
                                    decimalScale={2}
                                    allowNegative={false}
                                    customInput={Form.Control}
                                    onValueChange={(e) => setFormProd({ ...formProd, ['desconto']: e.floatValue || 0 })}
                                />
                                {error.desconto && <p className="text-xs text-red-500">{error.desconto}</p>}
                            </div> */}
                            <div className="w-full">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Desconto R$</Form.Label>
                                    <InputGroup.Text>{convertDecimal((formProd.descontoPc * (formProd.quantidade * formProd.preco)/100).toFixed(2))}</InputGroup.Text>
                                </Form.Group>
                            </div>
                            <div className="w-full">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Ipi</Form.Label>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Text>{props.item.ipi}</InputGroup.Text>
                                    </InputGroup>
                                </Form.Group>
                            </div>
                            <div className="w-full">
                                <Form.Group className="mb-3" >
                                    <Form.Label>Total</Form.Label>
                                    <InputGroup.Text>R$ {convertDecimal((((formProd.quantidade * formProd.preco) - (formProd.descontoPc * (formProd.quantidade * formProd.preco)/100).toFixed(2))*((props.item.ipi/100)+1)).toFixed(2))}</InputGroup.Text>                
                                </Form.Group>
                            </div>
                        </div>
                        <div className="md:w-1/3 w-full">
                            <Form.Group className="mb-3" >
                                <Form.Label>Pedido de compra</Form.Label>
                                <Form.Control value={formProd.pedido} name="pedido" onChange={onChangeForm} maxLength={8} type="text" />
                            </Form.Group>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" size="sm"   onClick={() => postForm()}>Adicionar no carrinho</Button>
                {/* <Button onClick={() => {setCarrinho([...carrinho, Object.assign(formProd, props.item)]);props.onHide()}}>Adicionar no carrinho</Button> */}
                <Button variant="danger" size="sm"  onClick={() => props.onHide()}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    );

}
export default AddCarrinho