import { useEffect } from 'react';
import { Navbar, Offcanvas, Nav, NavDropdown, Container } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { selectFilial, getFilial } from '../../store/Filiais';
import { logoutUser } from '../../store/Usuarios';
import logo from '../../images/LogoTovCare.png';

// const Sidebar2 = () => {
//   // const [filial, setFilial] = useState({id:'00', nome:'Filial'})
//   const { dataFilial, select } = useSelector(state => state.filialReduce)
//   const { dataUser } = useSelector(state => state.userReduce)
//   const navigate = useNavigate();
//   const dispatch = useDispatch()

//   useEffect(() => {
//     dispatch(getFilial())
//   }, [dispatch]);

//   return (
//     <div className="absolute top-0 left-0 right-0 bg-blue-400 w-full">
//       {[false].map((expand) => (
//         <Navbar key={expand} expand={expand} className="bg-blue-400">
//           <Container fluid className="bg-blue-400">
//             <b>
//               <NavDropdown
//                 title={select.nmFilial}
//                 id={`offcanvasNavbarDropdown-expand-${expand}`}
//               >
//                 {dataFilial.map((item) => { return (<NavDropdown.Item key={item.idFilial} onClick={() => dispatch(selectFilial(item))}><b>{item.nmFilial}</b></NavDropdown.Item>) })}
//               </NavDropdown>
//             </b>
//             <Navbar.Brand ><img src={logo} width="175" height="40"></img></Navbar.Brand>
//             <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
//             <Navbar.Offcanvas
//               id={`offcanvasNavbar-expand-${expand}`}
//               aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
//               placement="end"
//             >
//               <Offcanvas.Header closeButton>
//                 <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
//                   Usuário: {dataUser[0].nmUsuario + ' ' + dataUser[0].sobrenomeUsuario}
//                 </Offcanvas.Title>
//                 <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} onClick={() => logoutUser(() => navigate("/Login") )}>
//                   Sair
//                 </Offcanvas.Title>

//               </Offcanvas.Header>
//               <Offcanvas.Header>
//                 <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
//                   <Nav className="justify-content-end flex-grow-1 pe-3">
//                     <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
//                       Menus
//                     </Offcanvas.Title>
//                     {/* <NavDropdown
//                     title="Escolha Filial"
//                     id={`offcanvasNavbarDropdown-expand-${expand}`}
//                   >
//                     <NavDropdown.Item href="#action3"> Action </NavDropdown.Item>
//                     <NavDropdown.Item href="#action4"> Another action </NavDropdown.Item>
//                   </NavDropdown> */}
//                   </Nav>
//                 </Offcanvas.Title>
//               </Offcanvas.Header>
//               <Offcanvas.Body>
//                 <Nav className="justify-content-end flex-grow-1 pe-3" onSelect={(selectedKey) =>  navigate(selectedKey)}>
//                   {/* <Nav.Link href="/transportadoras">transportadora</Nav.Link> */}
//                   {/* <Nav.Link href="/Pedido">Pedidos</Nav.Link> */}
//                   {/* <Nav.Link href="/condPagamentos">Condição de Pagamento</Nav.Link> */}
//                   <Nav.Link eventKey="/orcamentos">Orçamento</Nav.Link>
//                 </Nav>
//               </Offcanvas.Body>
//             </Navbar.Offcanvas>
//           </Container>
//         </Navbar>
//       ))}
//     </div>
//   )
// }

function Sidebar() {

  const { dataFilial, select } = useSelector(state => state.filialReduce)
  const { dataUser } = useSelector(state => state.userReduce)

  const dispatch = useDispatch()
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getFilial())
  }, [dispatch]);


  const degrade = {
    borderBottom: '2px solid transparent',
    borderImage: 'linear-gradient(to right, #d90059, #47a5db)',
    borderImageSlice: 1
  };
  // Componente reutilizável com efeito hover
  const HoverNavLink = ({ to, children }) => {
    const handleMouseOver = (e) => {
      e.target.style.color = '#d90059';
      e.target.style.textDecoration = 'underline';
    };

    const handleMouseOut = (e) => {
      e.target.style.color = 'rgba(0, 0, 0, 0.55)';
      e.target.style.textDecoration = 'none';
    };

    return (
      <Nav.Link
        eventKey={to}
        style={{
          display: 'flex',
          alignItems: 'center',
          transition: 'color 0.3s ease',

        }}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        {children}
      </Nav.Link>
    );
  };  

  const logout = () => {
    dispatch(logoutUser())
    navigate('/')
  };

  return (
    <div>
      {[false].map((expand) => (
        <Navbar key={expand} expand="lg" collapseOnSelect className="bg-body-tertiary p-0" style={degrade}>
          <Container>
            <Navbar.Brand>
              <img alt='Logo TovCare' src={logo} width="175" height="40"></img>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto" onSelect={(select) => navigate(select)}>
                <HoverNavLink to="/orcamentos">Cotações</HoverNavLink>
              </Nav>
              <Nav >
                <Nav className="me-auto">
                  <HoverNavLink> {dataUser[0].nmVendedor}</HoverNavLink>
                </Nav>
                <Nav className="me-auto" onSelect={() => logout()}>
                  <HoverNavLink to="/">Sair</HoverNavLink>
                </Nav>

              </Nav>
            </Navbar.Collapse>
          </Container>

        </Navbar>

      ))}
      <NavDropdown
        // title={`${select.nmFilial}`}
        title={<b>{`${select.nmFilial}`}</b>}
        // id={`offcanvasNavbarDropdown-expand-${expand}`}
        className='p-3  ms-auto d-flex justify-content-end'
      >
        {dataFilial.map((item) => { return (<NavDropdown.Item key={item.idFilial} onClick={() => dispatch(selectFilial(item))}><b>{item.nmFilial}</b></NavDropdown.Item>) })}
      </NavDropdown>

    </div>
  );
}

export default Sidebar;